export default [
  {
    title: "Home",
    route: "home",
    icon: "HomeIcon",
    user_types: ["student"],
  },
  {
    title: "Home",
    route: "AgentHome",
    icon: "HomeIcon",
    user_types: ["agent", "franchise", "student_support"],
  },
  {
    title: "Home",
    route: "StaffHome",
    icon: "HomeIcon",
    user_types: [
      "operations",
      "counselor",
      "visa",
      "operations_manager",
      "services",
    ],
  },
  {
    title: "Home",
    route: "SchoolCounselorHome",
    icon: "HomeIcon",
    user_types: ["school_counselor"],
  },
  {
    title: "Home",
    route: "AccountsHome",
    icon: "HomeIcon",
    user_types: ["accounts"],
  },
  {
    title: "Profile Form",
    route: "profile_form",
    icon: "UserIcon",
    user_types: ["student"],
  },
  {
    title: "University Search",
    route: "University Search",
    icon: "SearchIcon",
    user_types: [
      "student",
      "counselor",
      "student_support",
      "agent",
      "franchise",
      "operations",
      "visa",
      "school_counselor",
      "operations_manager",
    ],
  },
  {
    title: "Application",
    route: "application",
    icon: "PackageIcon",
    user_types: ["student"],
  },
  {
    title: "University Preference",
    route: "Recommendation",

    icon: "ThumbsUpIcon",
    user_types: ["counselor", "franchise"],
    // children: [
    //   {
    //     title: "Recommendation",
    //     route: "Recommendation",
    //     resource: "training_hub",
    //     user_types: ["counselor", "agent", "franchise"],
    //   },
    //   {
    //     title: "Students",
    //     route: "RecommendedStudents",
    //     resource: "marketing_insight",
    //     user_types: ["counselor", "agent", "franchise"],
    //   },
    // ],
  },
  {
    title: "Students List",
    route: "Student List",
    icon: "ListIcon",
    user_types: [
      "operations",
      "visa",
      "counselor",
      "student_support",
      "agent",
      "franchise",
      "school_counselor",
      "accounts",
      "operations_manager",
      "services",
    ],
  },
  {
    title: "Applications",
    route: "Student Applications",
    icon: "GridIcon",
    user_types: [
      "operations",
      "visa",
      "counselor",
      "student_support",
      "agent",
      "franchise",
      "school_counselor",
      "operations_manager",
    ],
  },
  {
    title: "Documents",
    route: "documents",
    icon: "FileTextIcon",
    user_types: ["student"],
  },
  // {
  //   title: "Franchises",
  //   route: "Franchises",
  //   user_types: [
  //     "counselor",
  //   ],
  // },
  // {
  //   header: "--------------------------------------",
  //   user_types: [
  //     "operations",
  //     "visa",
  //     "counselor",
  //     "student_support",
  //     "agent",
  //     "franchise",
  //     "school_counselor",
  //     "accounts",
  //     "operations_manager",
  //   ],
  // },
  // {
  //   title: "Student Supports",
  //   route: "StudentSupport",
  //   icon: "UsersIcon",
  //   user_types: ["franchise"],
  // },
  // {
  //   title: 'Explore',
  //   route: 'Explore',
  //   icon: 'SearchIcon',
  //   user_types: ['counselor', "visa", 'student_counselor', 'agent', 'franchise, 'operations']
  // },
 
  
 
  {
    header: "--------------------------------------",
    user_types: [
      "operations",
      "visa",
      "counselor",
      "student_support",
      "agent",
      "franchise",
      "school_counselor",
      "accounts",
      "operations_manager",
    ],
  },

  // {
  //   title: "My Agents",
  //   route: "Assigned Agents",
  //   icon: "GridIcon",
  //   user_types: ["counselor"],
  // },
  // {
  //   title: "Training",
  //   route: "Training",
  //   resource: "training",
  //   user_types: [
  //     "student",
  //     "agent",
  //     "franchise",
  //     "counselor",
  //     "operations",
  //     "visa",
  //     "student_support",
  //     "accounts",
  //     "operations_manager",
  //   ],
  // },
  // {
  //   header: "--------------------------------------",
  // },
  {
    title: "My Leads",
    route: "LeadListChart",
    icon: "UserPlusIcon",
    user_types: ["agent", "counselor", "franchise", "student_support"],
    // children: [
    //   {
    //     title: "Lead Chart",
    //     route: "Lead Chart",
    //     resource: "lead_chart",
    //     user_types: ["agent", "counselor", "franchise", "student_support"],
    //   },
    //   {
    //     title: "Lead List",
    //     route: "My Leads",
    //     resource: "lead_list",
    //     user_types: ["agent", "counselor", "franchise", "student_support"],
    //   },
    // ],
  },
  {
    title: "Resources",
    route: "resources",
    icon: "PackageIcon",
    children: [
      {
        title: "Academy",
        route: "Academy",
        resource: "training_hub",
        user_types: ["counselor", "agent", "franchise"],
      },
      
      {
        title: "Utilities",
        route: "MarketingInsights",
        resource: "marketing_insight",
        user_types: ["counselor", "agent", "franchise"],
      },
      {
        title: "University USP",
        route: "University USP",
        resource: "university_usp",
        user_types: [
          "agent",
          "franchise",
          "counselor",
          "operations",
          "visa",
          "operations_manager",
          "student_support",
          "accounts",
        ],
      },
      // {
      //   title: "Community Help",
      //   route: "CommunityHelp",
      //   resource: "community_help",
      //   user_types: [
      //     "agent",
      //     "franchise",
      //     "counselor",
      //     "operations",
      //     "operations_manager",
      //     "visa",
      //     "student_support",
      //     "accounts",
      //   ],
      // },
    ],
    user_types: [
      "agent",
      "franchise",
      "counselor",
      "operations",
      "visa",
      "student_support",
      "accounts",
      "operations_manager",
    ],
  },
  
  {
    title: "Activities",
    route: "TrainingByAliffAndUniiversity",
    resource: "",
    icon: "CalendarIcon",
    user_types: [
      // "agent",
      "franchise",
      "counselor",
      // "operations_manager",
    ],
    children: [{
      title: "Events",
      route: "Events",
      resource: "events",
      user_types: ["counselor", "franchise"],
    },
    {
      title: "Trainings",
      route: "TrainingByAliffAndUniiversity",
      resource: "trainings",
      user_types: ["counselor", "franchise"],
    },]
  },
  {
    title: "Shop",
    icon: "ShoppingBagIcon",
    route: "Shop",
    resource: "shop",
    user_types: ["franchise"],
  },
  {
    title: "Services",
    icon: "LayersIcon",
    route: "services",
    user_types: [
      "student",
      "counselor",
      "student_support",
      "agent",
      "franchise",
      "services",
    ],
  },
  {
    title: "Shop Orders",
    icon: "ShoppingBagIcon",
    route: "Accounts Shop Orders",
    resource: "shop_orders",
    user_types: ["accounts", "counselor"],
  },
  
 
  // {
  //   title: "Leads",
  //   route: "Leads",
  //   icon: "UserPlusIcon",
  //   user_types: ["counselor"],
  // },
  
  {
    header: "--------------------------------------",
    user_types: [
      "operations",
      "visa",
      "counselor",
      "student_support",
      "agent",
      "franchise",
      "school_counselor",
      "accounts",
      "operations_manager",
    ],
  },
  // {
  //   title: "Invoices",
  //   route: "Invoices",
  //   icon: "PercentIcon",
  //   user_types: ["franchise", "accounts"],
  // },
  {
    title: "Tasks",
    icon: "CheckSquareIcon",
    route: "apps-todo",
    user_types: [
      // "student",
      "counselor",
      // "student_support",
      // "agent",
      "franchise",
      "operations",
      "visa",
      "accounts",
      "operations_manager",
      "services"
      // "school_counselor"
    ],
  },
  {
    title: "Transactions",
    route: "Transactions",
    icon: "DollarSignIcon",
    user_types: ["counselor", "accounts"],
    // children: [
      //   {
        //     title: "Invoices",
        //     route: "Invoice",
        //     resource: "lead_chart",
        //     user_types: ["accounts", "franchise"],
        //   },
        // ],
      },
      {
        title: "Announcements",
        route: "Announcements",
        icon: "ClockIcon",
        user_types: [
          "student",
          "counselor",
          "franchise",
          "agent",
          "student_support",
          "operations",
          "visa",
          "accounts",
          "operations_manager",
          
      "services"
        ],
      },
      
      {
        title: "Sample Document",
        route: "SampleDocument",
        icon: "FileTextIcon",
        resource: "sample_document",
        user_types: [
          // "student",
          "counselor",
          "student_support",
          "agent",
          "franchise",
          "operations",
          "visa",
          "school_counselor",
          "operations_manager",
        ],
      },
  
{
  title: "University Commission",
  route: "UniversityCommissions",
  icon: "PieChartIcon",
  user_types: ["accounts"],
},
  // {
  //   title: "Cluster Profile",
  //   route: "ClusterProfile",
  //   icon: "PieChartIcon",
  //   user_types: ["counselor", "operations"],
  // },
  {
    title: "Notifications",
    route: "Notifications",
    icon: "BellIcon",
    user_types: [
      // "student",
      "counselor",
      "student_support",
      "agent",
      "franchise",
      "operations",
      "visa",
      "school_counselor",
      "accounts",
      "operations_manager",
      "services"
    ],
  },
  // {
  //   title: "Chat",
  //   route: "chat",
  //   icon: "MessageSquareIcon",
  //   user_types: [
  //     "student",
  //     "counselor",
  //     "student_support",
  //     "agent",
  //     "franchise",
  //     "operations",
  //     "visa",
  //     "school_counselor",
  //     "accounts",
  //     "operations_manager",
  //   ],
  // },
];
